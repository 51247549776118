import isAbsoluteUrl from 'is-absolute-url';
import React from 'react';


import thumbnailImage from '../assets/img/blootropic-logo-colored-seo-thumbnail.png';
import defaultImage from '../assets/img/blootropic-logo-colored-seo.png';

import orgLogo from '../assets/img/logo-colored-seo-logoicon.png';
import orgImage from '../assets/img/logo-colored-seo-logotext.png';

import { useSeoMetadata } from '../shared/hooks/useSeoMetadata';

type HeadComponentProps = {
  title?: string
  description?: string
  pathname: string
  image?: { url?: string; publicURL?: string }
  children?: React.ReactNode
}

// export type PrefetchElements = {
//   images?: ContentfulAsset[]
// }

export default function SeoHead({
  title,
  description,
  pathname,
  image,
  children,
}: HeadComponentProps) {
  const seoMetadata = useSeoMetadata();

  const seo = {
    title: title ? `${seoMetadata.site.title} - ${title}` : seoMetadata.site.title,
    description: description || seoMetadata.site.description,
    image: image?.url || image?.publicURL || (defaultImage as string),
    url: `${seoMetadata.site.siteUrl}${pathname || ``}`
  }
  if (!isAbsoluteUrl(seo.image)) {
    // remove leading slash
    seo.image = seo.image.replace(/^\//, '')
    seo.image = `${seoMetadata.site.siteUrl}/${seo.image}`
  }

  return (
    <>
      <html lang="en" />
      <meta charSet="utf-8" />
      <title>{seo.title}</title>
      <meta id="meta-desc" name="description" content={seo.description} />
      <meta id="meta-image" name="image" content={seo.image} />
      <meta id="meta-adsense" name="google-adsense-account" content="ca-pub-7404596419417719" />

      <meta id="meta-twitter-card" name="twitter:card" content="summary_large_image" />
      <meta id="meta-twitter-title" name="twitter:title" content={seo.title} />
      <meta id="meta-twitter-url" name="twitter:url" content={seo.url} />
      <meta id="meta-twitter-desc" name="twitter:description" content={seo.description} />
      <meta id="meta-twitter-img" name="twitter:image" content={seo.image} />

      <meta id="meta-og-title" property="og:title" content={seo.title} />
      <meta id="meta-og-sitename" property="og:site_name" content={seoMetadata.site.title} />
      <meta id="meta-og-type" property="og:type" content="website" />
      <meta id="meta-og-desc" property="og:description" content={seo.description} />
      <meta id="meta-og-url" property="og:url" content={seo.url} />
      <meta id="meta-og-img" property="og:image" content={seo.image} />

      <meta id="meta-thumb" name="thumbnail" content={seoMetadata.site.siteUrl + thumbnailImage} />
      <meta id="meta-viewport" name="viewport" content="initial-scale=1, width=device-width" />
      <link id="link-rel" rel="canonical" href={seo.url} />
      <link id="link-icon" rel="icon" href={seoMetadata.site.siteUrl + thumbnailImage} type="image/x-icon" />

      <script type="application/ld+json" id="schema-org" key="schema-org">
        {`
          {
              "@context": "https://schema.org",
              "@type": "Organization",
              "image": "${seoMetadata.site.siteUrl}${orgImage}",
              "logo": "${seoMetadata.site.siteUrl}${orgLogo}",
              "name": "${seoMetadata.site.title}",
              "url": "${seoMetadata.site.siteUrl}",
              "email": "support@blootropic.com",
              "legalName": "Blootropic",
              "description": "${seoMetadata.site.description}",
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": ${seoMetadata.site.storeReviews.rating},
                "reviewCount": ${seoMetadata.site.storeReviews.count}
              }
          }
        `}
      </script>

      <meta id="meta-fragment" name="fragment" content="!" />

      {children}
    </>
  )
}
