import { last } from 'lodash-es';

import type {
    CommerceActivityTrackingEventProductMetadata
} from '@seeka-labs/converge';
import {
    ECommerceContentType
} from '@seeka-labs/converge';
import { useSeekaConverge } from '@seeka-labs/converge-react';
import { AnalyticsPageType, ShopifyAnalyticsProduct, useCart } from '@shopify/hydrogen-react';

import { ShopifyPageViewPayloadMinimal } from '../models/tracking';
import { useShopifyAnalytics } from '../shopify/analytics';
import { antiSlugify } from '../utils';

type ProductMetafields = Queries.ProductDetailPageQuery['shopifyStorefront']['products']['nodes'][0]['metafields']

export const useAnalytics = () => {
    const shopifyAnalytics = useShopifyAnalytics();
    const converge = useSeekaConverge();
    const isSsr = typeof window === 'undefined';
    const cart = useCart();

    const trackPageView = async (pageType: string, data?: ShopifyPageViewPayloadMinimal, variantId?: string, productMetafields?: ProductMetafields) => {
        if (isSsr) return;

        shopifyAnalytics.trackPageView(pageType, {
            ...data,
            products: data?.products.map(p => shopifyProductFragmentToShopifyAnalyticsProduct(p, variantId))
        })

        if (data?.products && data?.products.length > 0) {
            if (pageType === AnalyticsPageType.collection || pageType === AnalyticsPageType.listCollections) {
                const shopifyProducts = data?.products.map(p => shopifyProductToSeekaProduct(p, variantId, productMetafields));
                const contentItem = {
                    contentType: ECommerceContentType.Collection,
                    contentName: data.collectionHandle,
                    // currencyCode: variants && variants.length > 0 ? variants[0].price?.currencyCode : '',
                    value: undefined,
                    identifiers: shopifyProducts.map(p => p.variantIdentifier || p.productIdentifier),
                    items: data?.products.map(p => shopifyProductToSeekaProduct(p, variantId, productMetafields))
                };
                converge.track.viewContentItem(contentItem)
            }
            else {
                data.products.forEach(product => {
                    // Seeka
                    converge.track.viewProduct(shopifyProductToSeekaProduct(product, variantId, productMetafields))

                    const klavProduct = shopifyProductToKlaviyoProduct(product, 1, variantId);
                    const klav = !isSsr ? (window as any).klaviyo : undefined;

                    if (klav) {
                        // Klaviyo
                        // https://developers.klaviyo.com/en/v1-2/docs/guide-to-integrating-a-platform-without-a-pre-built-klaviyo-integration#viewed-product
                        klav.push(["track", "Viewed Product", {
                            "ProductName": klavProduct.ProductName,
                            "ProductID": klavProduct.ProductID,
                            "SKU": klavProduct.SKU,
                            "Categories": klavProduct.ProductCategories,
                            "ImageURL": klavProduct.ImageURL,
                            "URL": klavProduct.ProductURL,
                            "Brand": klavProduct.brandName,
                            "Price": klavProduct.unitPrice,
                            // "CompareAtPrice": 14.99
                        }]);

                        klav.push(["trackViewedItem", {
                            "Title": klavProduct.ProductName,
                            "ItemId": klavProduct.ProductID,
                            "Categories": klavProduct.ProductCategories,
                            "ImageUrl": klavProduct.ImageURL,
                            "Url": klavProduct.ProductURL,
                            "Metadata": {
                                "Brand": product.brand,
                                "Price": klavProduct.ItemPrice,
                                // "CompareAtPrice": product.
                            }
                        }]);
                    }
                })
            }
        }
        // converge.track.viewPage();
    }

    const trackAddToCart = async (products: Queries.ShopifyProductCardFragment[], variantId?: string, quantity?: number) => {
        if (isSsr) return;

        shopifyAnalytics.trackAddToCart([
            ...products.map(p => shopifyProductFragmentToShopifyAnalyticsProduct(p, variantId, quantity || 1))
        ])

        products.forEach(product => {
            converge.track.addToCart({
                ...shopifyProductToSeekaProduct(product, variantId),
                quantity: quantity || 1
            })
        })

        // Klaviyo
        const klav = !isSsr ? (window as any).klaviyo : undefined;
        if (klav) {
            // https://developers.klaviyo.com/en/v1-2/docs/guide-to-integrating-a-platform-without-a-pre-built-klaviyo-integration#viewed-product
            const productsMapped = products.map(e => shopifyProductToKlaviyoProduct(e, quantity, variantId));
            if (productsMapped.length > 0) {
                const klavProduct = productsMapped[0];

                klav.push(["track", 'Added to Cart', {
                    "$value": klavProduct.RowTotal,
                    "AddedItemProductName": klavProduct.ProductName,
                    "AddedItemProductID": klavProduct.ProductID,
                    "AddedItemSKU": klavProduct.SKU,
                    "AddedItemCategories": klavProduct.ProductCategories,
                    "AddedItemImageURL": klavProduct.ImageURL,
                    "AddedItemURL": klavProduct.ProductURL,
                    "AddedItemPrice": klavProduct.ItemPrice,
                    "AddedItemQuantity": quantity,
                    "ItemNames": products.map(e => e.name),
                    "CheckoutURL": cart.checkoutUrl,
                    "Items": productsMapped
                }]);
            }
        }
    }

    return {
        trackPageView,
        trackAddToCart
    }
}

const shopifyProductFragmentToShopifyAnalyticsProduct = (product: Queries.ShopifyProductCardFragment, variantId?: string, quantity?: number): ShopifyAnalyticsProduct => {
    const variant = variantId ? getShopifyVariant(product, variantId) : null;
    const p: ShopifyAnalyticsProduct = {
        productGid: product.shopifyId,
        name: product.name,
        brand: product.brand,
        variantGid: variant ? variant.shopifyId : null,
        variantName: variant ? variant.title : null,
        category: product.productType,
        sku: variant ? variant.sku : null,
        price: (variant ? variant.price : product.priceRangeV2.maxVariantPrice.amount).toFixed(2),
    }

    if (quantity != null && quantity != undefined) {
        p.quantity = quantity;
    }

    return p;
}

const getShopifyVariant = (product: Queries.ShopifyProductCardFragment, variantId: string) => {
    return product.variants.find(v => v.shopifyId === variantId);
}

export const removeShopifyIdPath = (shopifyId: string) => {
    if (!shopifyId) return null;
    return last(shopifyId.split('/'));
}

const shopifyProductToSeekaProduct = (product: Queries.ShopifyProductCardFragment, variantId?: string, productMetafields?: ProductMetafields): CommerceActivityTrackingEventProductMetadata => {
    const variant = variantId ? getShopifyVariant(product, variantId) : null;

    const groupSkuField = productMetafields?.find(metafield => metafield.key == 'group_sku')

    return {
        productIdentifier: removeShopifyIdPath(product.storefrontId),
        variantIdentifier: variant ? removeShopifyIdPath(variant.storefrontId) : null,
        categoryName: antiSlugify(product.productType),
        brandName: product.brand,
        type: ECommerceContentType.SingleProduct,
        productName: product.name,
        variantName: variant ? variant.title : null,
        sku: variant ? variant.sku : groupSkuField?.value,
        unitPrice: variant ? variant.price : product.priceRangeV2.maxVariantPrice.amount,
        currencyCode: product.priceRangeV2.maxVariantPrice.currencyCode,
    }
}

const shopifyProductToKlaviyoProduct = (product: Queries.ShopifyProductCardFragment, quantity: number, variantId?: string): any => {
    const variant = variantId ? getShopifyVariant(product, variantId) : null;

    const [firstImage] = product.media;
    const imageUrl = (firstImage as any).image.thumbnail.childImageSharp.gatsbyImageData.images.fallback.src;

    const price = variant ? variant.price : product.priceRangeV2.maxVariantPrice.amount;
    const categoryName = antiSlugify(product.productType);

    const slug = `/products/${product.handle}`;

    // TODO: get quan
    const klavProduct = {
        "ProductID": removeShopifyIdPath(product.storefrontId),
        // "ProductID": variant ? removeShopifyIdPath(variant.storefrontId) : removeShopifyIdPath(product.storefrontId),
        "SKU": variant ? variant.sku : null,
        "ProductName": product.name,
        "Quantity": quantity,
        "ItemPrice": price,
        "RowTotal": price * quantity,
        "ProductURL": `${window.location.protocol}//${window.location.hostname}${window.location.protocol !== 'https:' ? (':' + window.location.port) : ''}${slug}`,
        "ImageURL": imageUrl,
        "ProductCategories": categoryName ? [categoryName] : [],
    }

    // console.log('heree', klavProduct)

    return klavProduct
}