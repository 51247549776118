import { graphql, useStaticQuery } from 'gatsby';
import { RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import React from 'react';

import { Box } from '@mui/material';
import {
  useContentfulInspectorMode, useContentfulLiveUpdates
} from '../../shared/hooks/contentful';

import { FaqItem } from './faqItem';

export interface FaqListItem {
  id: string
  title: string
  contentful_id: string
  content: RenderRichTextData<null>
}

export const FaqList = () => {
  const query: Queries.FaqListQuery = useStaticQuery(graphql`
    query FaqList {  
      faqList: contentfulFaqList {
        contentful_id
        __typename
        items {
          id
          title
          content {
            raw
          }
          contentful_id
          __typename
        }
      }
    }
  `)

  const inspectorProps = useContentfulInspectorMode();
  const data = useContentfulLiveUpdates({
    ...query.faqList,
    sys: { id: query.faqList.contentful_id },
  });

  return (
    <Box {...inspectorProps({
      entryId: data.contentful_id,
      fieldId: 'items',
    })}>
      {data.items.map((item) => (
        <FaqItem
          key={item.id}
          item={item}
        />))}
    </Box>
  )
}
