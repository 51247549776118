import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { convertSchemaToHtml } from '@thebeyondgroup/shopify-rich-text-renderer';
import React from 'react';

type Props = {
    metafields: Queries.ProductDetailPageQuery['shopifyStorefront']['products']['nodes'][0]['metafields']
}

type ProductTabProps = {
    content: string
    title: string
    id: string
}

const ProductTab = ({ content, title, id }: ProductTabProps) => {
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`product-panel-content-${id}`}
                id={`product-panel-header-${id}`}
            >
                {title}
            </AccordionSummary>
            <AccordionDetails>
                <div
                    className="html"
                    dangerouslySetInnerHTML={{
                        __html: convertSchemaToHtml(content),
                    }}
                />
            </AccordionDetails>
        </Accordion>
    )
}

export const ProductTabs = ({ metafields }: Props) => {
    const tabs = metafields.filter(metafield => metafield.key == 'tabs').flatMap(e => e.references).flatMap(e => e.nodes).map((e, index) => {
        const fields = (e as any).fields as any[];
        return {
            id: index.toString(),
            title: fields.find(f => f.key === 'title')?.value,
            content: fields.find(f => f.key === 'content')?.value
        }
    })
    if (!tabs || tabs.length === 0) return <></>

    return (
        <Box>
            {tabs.map((tab) => {
                return <ProductTab key={tab.id} content={tab.content} title={tab.title} id={tab.id} />
            })}
        </Box>
    )
}