import React, { useMemo } from 'react';

import { Box, Divider, Stack, Theme, Typography, useTheme } from '@mui/material';
import { formatDistanceToNow } from "date-fns";
import { useAutomaticDiscountList } from '../shared/hooks/useAutomaticDiscountList';

type Props = {
    banners?: { text: string }[]
}

export const bannerHeight = (isBigDefautDiscount: boolean) => isBigDefautDiscount ? 40 : 40;

const useStyles = (theme: Theme) => ({
    container: {
        // position: 'absolute',
        // zIndex: 99,
        // top: 0,
        // left: 0,
        fontWeight: theme.typography.fontWeightBold,
        // color: theme.palette.warning.contrastText,
        backgroundColor: theme.palette.warning.dark,
        width: '100%'
    },
    content: {
        textAlign: 'center',
        fontSize: {
            xs: '0.8rem',
            sm: 'initial',
            md: 'initial'
        }
    },
    divider: {
        borderColor: theme.palette.text.primary,
        height: '20px'
    }
})

export const SiteBanner = ({ banners }: Props) => {
    const theme = useTheme();
    const styles = useStyles(theme);

    const { defaultDiscount, isBigDefaultDiscount } = useAutomaticDiscountList();

    const filteredBanners = useMemo(() => {
        if (!defaultDiscount || !banners) return banners || [];

        if (defaultDiscount) return [];

        return banners.filter(e => e.text !== defaultDiscount.title);
    }, []);

    if ((!filteredBanners || filteredBanners.length === 0) && (!defaultDiscount)) return <></>;

    // console.log('heree', isBigDefaultDiscount, defaultDiscount)

    const discountEndsAt = defaultDiscount && defaultDiscount.endsAt ? formatDistanceToNow(new Date(defaultDiscount.endsAt)) : null;

    return (
        (<Box sx={styles.container}>
            <Stack direction={"row"} sx={{ height: (bannerHeight(isBigDefaultDiscount) + 'px') }}
                divider={<Divider orientation="vertical" sx={styles.divider} />}
                spacing={{ xs: 1, sm: 1, md: 2 }} alignItems='center' justifyContent='center'>
                {filteredBanners.map((banner, index) => (
                    <Box sx={{ ...styles.content, display: { xs: index > 1 ? 'none' : 'inherit', sm: 'inherit' } }} key={index}>
                        <Typography variant="h5" sx={[{
                            fontSize: {
                                sm: 'initial'
                            }
                        }, filteredBanners.length > 1 ? {
                            fontSize: {
                                xs: '0.8rem'
                            }
                        } : {
                            fontSize: {
                                xs: 'initial'
                            }
                        }]}>{banner.text}</Typography>
                    </Box>
                ))}
                {defaultDiscount && (
                    <Box sx={{ ...styles.content, display: { xs: 'inherit', sm: 'inherit', md: 'inherit' } }}>
                        <Stack direction="row" spacing={1} alignItems='center'>
                            {/* <Celebration /> */}
                            <Typography variant="h4" sx={[{
                                fontSize: {
                                    sm: 'initial'
                                }
                            }, filteredBanners.length > 1 ? {
                                fontSize: {
                                    xs: '0.8rem'
                                }
                            } : {
                                fontSize: {
                                    xs: 'initial'
                                }
                            }]}> {defaultDiscount.title}{discountEndsAt ? ` - ends in ${discountEndsAt}` : ''}</Typography>
                            {/* <Celebration /> */}
                        </Stack>

                    </Box>
                )}
            </Stack>
        </Box>)
    );
}