import React from 'react';

import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
    <svg width="60px" height="40px" viewBox="0 0 60 40" version="1.1" xmlns="http://www.w3.org/2000/svg">

        <title>diners-club</title>
        <desc>Created with Sketch.</desc>
        <defs></defs>
        <g id="diners-club" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <rect fill="#F3F6F9" x="0" y="0" width="60" height="40"></rect>
            <g id="Group" transform="translate(16.000000, 7.000000)" fill-rule="nonzero">
                <path d="M0.642002797,12.991158 C0.656323581,6.75958003 5.71710881,1.71816027 11.9486959,1.7277308 C18.180283,1.73730133 23.2255591,6.79424207 23.2207388,13.0258347 C23.2159186,19.2574273 18.1628252,24.3065567 11.9312308,24.3064867 C8.93367398,24.3030457 6.06025848,23.1089604 3.94311318,20.9869203 C1.82596788,18.8648802 0.638521448,15.9887148 0.642002797,12.991158" id="Fill-42" fill="#FFFFFF"></path>
                <path d="M18.7587077,12.7683916 C18.7573271,9.95524832 17.0254931,7.4327906 14.4006741,6.42081119 L14.4006741,19.1154042 C17.0252817,18.1034767 18.7570647,15.5813188 18.7587077,12.7683916 Z M9.54304615,19.1129175 L9.54304615,6.42206434 C6.91886253,7.43314143 5.18777755,9.95524506 5.18777755,12.7674713 C5.18777755,15.5796976 6.91886253,18.1018012 9.54304615,19.1129175 Z M11.9731133,2.0384979 C7.63182115,2.02895045 3.71266388,4.63666083 2.04457559,8.64470151 C0.376487291,12.6527422 1.28828996,17.2710279 4.35447708,20.3443657 C7.42066419,23.4177035 12.0368134,24.3402615 16.0487289,22.681514 C20.0606443,21.0227665 22.6774768,17.1096942 22.678042,12.7683916 C22.6844369,6.84955778 17.8920047,2.04592273 11.973172,2.0384979 L11.9731133,2.0384979 Z M11.9468755,24.5080867 C5.4869637,24.5444634 0.213733097,19.3505105 0.152257343,12.8907888 C0.0772284027,9.73780195 1.29486068,6.69127911 3.52243157,4.45859385 C5.75000246,2.2259086 8.79372489,1.00129292 11.9468755,1.06909091 L14.9787273,1.06909091 C18.1733541,0.996198679 21.2642245,2.20515133 23.5616173,4.42617369 C25.8590101,6.64719605 27.1717264,9.69544504 27.2068364,12.8907105 C26.9020602,19.4140737 21.5091392,24.5376107 14.9787273,24.5080084 L11.9468755,24.5080867 Z" id="Fill-43" fill="#154A78"></path>
            </g>
        </g>
    </svg>
    , '')