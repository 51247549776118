import React from 'react';

import type { SelectProps } from '@mui/material';
import MenuItem from '@mui/material/MenuItem/MenuItem';

import Select from '@mui/material/Select';

const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]

type Props = Exclude<SelectProps<number>, 'onChange'> & {
  onChange: (value: number) => void,
  includeZero?: boolean
}

export function NumericInput({
  onChange,
  includeZero,
  id,
  ...other
}: Props) {
  const randId = React.useId();
  const controlId = id || randId
  return (
    <Select
      {...other}
      id={controlId}
      aria-controls={controlId}
      onChange={(e) => onChange(e.target.value as number)}
      SelectDisplayProps={{ 'aria-controls': controlId }}
      MenuProps={{ 'aria-controls': controlId }}
      slotProps={{
        input: { 'aria-controls': controlId },
        root: { 'aria-controls': controlId }
      }}
    >
      {includeZero && <MenuItem id={`${controlId}-${0}`} value={0}>Remove</MenuItem>}

      {options.map((option, i) => (
        <MenuItem key={option} id={`${controlId}-${option}`} value={option}>{option}</MenuItem>
      ))}

      {other.value && options.indexOf(other.value) === -1 ? <MenuItem value={other.value} id={`${controlId}-${other.value}`}>{other.value}</MenuItem> : null}
    </Select>
  )
}
