import { indexOfFirstProductImage } from "../../pages/products/{ShopifyProduct.handle}";
import { removeShopifyIdPath } from "../analytics";
export const shopifyImageToJsonLd = (image: { url: string; height: number, width: number }) => {

    // const gatsbyImageData = getShopifyImage({
    //     image: image,
    //     width: 800,
    //     height: 800,
    //     layout: "fixed",
    // })

    return {
        "@type": "ImageObject",
        "url": image.url,
        "width": image.height,
        "height": image.width
    }
}

export type AggregateRatingInfo = {
    reviewCount: number
    ratingValue: number
}

export const shopifyProductToJsonLd = (product: Queries.ProductDetailPageQuery['product'], shopifyAdmin: Queries.ProductDetailPageQuery['shopifyAdmin'], siteUrl: string, rating: AggregateRatingInfo | undefined, reviews: Queries.ProductDetailPageQuery['reviewList']['reviews']): string => {
    const {
        title,
        description,
        seo1x1Image,
        seo16x9Image,
        seo4x3Image,
        media
    } = product
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    currentDate.setDate(currentDate.getDate() + 14);
    const productIdNumber = removeShopifyIdPath(product.shopifyId);

    const shopifyAdminProduct = shopifyAdmin.products.nodes[0];
    const metafields = shopifyAdminProduct.metafields;
    const seoDescription = metafields.nodes?.find(metafield => metafield.key == 'description_tag')?.value || description;

    // console.log('heree Head', media)

    const jsonLdImages = [];
    if (seo1x1Image) {
        jsonLdImages.push(shopifyImageToJsonLd(seo1x1Image.preview.image));
    }
    if (seo16x9Image) {
        jsonLdImages.push(shopifyImageToJsonLd(seo16x9Image.preview.image));
    }
    if (seo4x3Image) {
        jsonLdImages.push(shopifyImageToJsonLd(seo4x3Image.preview.image));
    }
    if (jsonLdImages.length === 0 && media && media.length > 0) {
        const img: any = media[indexOfFirstProductImage];
        if (img.image) {
            jsonLdImages.push(shopifyImageToJsonLd(img.image));
        }
    }

    const variantJsonLd = product.variants.map((variant) => {
        const variantIdNumber = removeShopifyIdPath(variant.shopifyId);
        return {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": title + " " + variant.title,
            "image": jsonLdImages[0]["url"],
            "description": seoDescription,
            "sku": variant.sku,
            "size": variant.title,
            "isVariantOf": { "@id": "#" + productIdNumber },
            "offers": {
                "@type": "Offer",
                "identifier": "shopify_AU_" + productIdNumber + "_" + variantIdNumber,
                "priceCurrency": variant.presentmentPrices[0].price.currencyCode,
                "price": variant.presentmentPrices[0].price.amount,
                "url": siteUrl + "/products/" + product.handle + '/?variant=' + variantIdNumber,
                "itemCondition": "https://schema.org/NewCondition",
                "availability": "https://schema.org/InStock",
                "seller": {
                    "@type": "Organization",
                    "name": "Blootropic"
                },
                "priceValidUntil": currentDate.toISOString(),
                "shippingDetails": { "@id": "#shipping_policy" },
                "hasMerchantReturnPolicy": { "@id": "#return_policy" }
            }
        }
    })

    const productGroup = {
        "@context": "http://schema.org",
        "@type": "ProductGroup",
        "@id": "#" + productIdNumber,
        "productGroupID": productIdNumber,
        "variesBy": [
            "https://schema.org/size"
        ],
        "name": title,
        "description": seoDescription,
        "brand": {
            "@type": "Brand",
            "name": product.vendor,
        },
        "hasVariant": variantJsonLd
    }
    if (rating) {
        (productGroup as any).aggregateRating = {
            "@type": "AggregateRating",
            "ratingValue": rating.ratingValue,
            "reviewCount": rating.reviewCount
        }
    }
    if (reviews && reviews.length > 0) {
        const filteredReviews = reviews.filter((review) => review.featured);
        if (filteredReviews.length > 0) {
            (productGroup as any).review = reviews.map((review) => {
                return {
                    "@type": "Review",
                    "reviewBody": review.body,
                    "name": review.title,
                    "author": {
                        "@type": "Person",
                        "name": review.reviewer?.name
                    },
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": review.rating,
                        "bestRating": 5,
                        "worstRating": 1
                    }
                }
            })
        }
    }

    const jsonLd = [
        productGroup,
        {
            "@context": "https://schema.org/",
            "@type": "MerchantReturnPolicy",
            "@id": "#return_policy",
            "applicableCountry": "AU",
            "returnPolicyCategory": "https://schema.org/MerchantReturnFiniteReturnWindow",
            "merchantReturnDays": 120,
            "returnMethod": "https://schema.org/ReturnByMail",
            "returnFees": "https://schema.org/FreeReturn"
        },
        {
            "@context": "https://schema.org/",
            "@type": "OfferShippingDetails",
            "@id": "#shipping_policy",
            "shippingRate": {
                "@type": "MonetaryAmount",
                "value": 0,
                "currency": "AUD"
            },
            "shippingDestination": {
                "@type": "DefinedRegion",
                "addressCountry": "AU"
            },
            "deliveryTime": {
                "@type": "ShippingDeliveryTime",
                "handlingTime": {
                    "@type": "QuantitativeValue",
                    "minValue": 0,
                    "maxValue": 1,
                    "unitCode": "DAY"
                },
                "transitTime": {
                    "@type": "QuantitativeValue",
                    "minValue": 2,
                    "maxValue": 6,
                    "unitCode": "DAY"
                }
            }
        }
    ]

    return JSON.stringify(jsonLd, null, 2)
}