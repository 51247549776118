import React from 'react';

import styled from '@emotion/styled';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Theme, useTheme } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import {
  useContentfulInspectorMode, useContentfulLiveUpdates
} from '../../shared/hooks/contentful';

import { notPrintable } from '../../shared/styles/notPrintable';
import { printOnly } from '../../shared/styles/printOnly';
import { renderContentfulRichText } from '../../shared/utils';
import { FaqListItem } from './faqList';

const useStyles = (theme: Theme) => ({
  accordion: {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  accordionSummary: {
    borderBottom: "1px solid gainsboro",
  },
})

interface Props {
  item: FaqListItem
}

export const FaqItem = ({ item }: Props) => {
  const theme = useTheme()
  const styles = useStyles(theme)

  const NotPrintable = styled('div')(notPrintable);
  const PrintOnly = styled('div')({
    ...printOnly
  });

  const inspectorProps = useContentfulInspectorMode();
  const data = useContentfulLiveUpdates({
    ...item,
    sys: { id: item.contentful_id },
  });

  return (
    <>
      <NotPrintable>
        <Accordion sx={styles.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`faq-panel-content-${item.contentful_id}`}
            id={`faq-panel-header-${item.contentful_id}`}
            sx={styles.accordionSummary}
          >
            <Typography style={{ fontWeight: "bold" }} color="textSecondary"  {...inspectorProps({
              entryId: item.contentful_id,
              fieldId: 'title',
            })}>
              {data.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: "18px", paddingBottom: "45px" }}>
            <Typography variant="body1" component="div" color="textSecondary" {...inspectorProps({
              entryId: item.contentful_id,
              fieldId: 'content',
            })}>
              {renderContentfulRichText(data.content)}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </NotPrintable>

      <PrintOnly>
        <Box sx={{ pb: 4, pageBreakInside: 'avoid' }}>
          <Typography style={{ fontWeight: "bold" }} gutterBottom>
            {data.title}
          </Typography>
          <Typography variant="body1" component="div">
            {renderContentfulRichText(data.content)}
          </Typography>
        </Box>
      </PrintOnly>
    </>
  )
}
