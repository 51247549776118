import { graphql, useStaticQuery } from 'gatsby';

export const useSeoMetadata = () => {
  const data: Queries.SeoMetadataQuery = useStaticQuery(graphql`
    query SeoMetadata {
      site {
        siteMetadata {
          title
          description
          image
          siteUrl
        }
      }
      judgeMeStoreReviewsCount {
        all_reviews_count
      }
      judgeMeStoreReviewsRating {
        all_reviews_rating
      }
    }
  `)

  return {
    site: {
      ...data.site.siteMetadata,
      storeReviews: {
        count: data.judgeMeStoreReviewsCount.all_reviews_count,
        rating: data.judgeMeStoreReviewsRating.all_reviews_rating
      },
      image: `${data.site.siteMetadata.siteUrl}${data.site.siteMetadata.image}`
    }
  }
}