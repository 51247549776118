import { WrapRootElementBrowserArgs, WrapRootElementNodeArgs } from 'gatsby';
import { SnackbarProvider } from 'notistack';
import React from 'react';

// import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react';

import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { CartProvider, ShopifyProvider, useShopifyCookies } from '@shopify/hydrogen-react';
import { CountryCode, LanguageCode } from '@shopify/hydrogen-react/storefront-api-types';

import { cartFragment } from './src/shared/shopify/graphql/fragments';
import theme from './src/shared/theme';

// import { StoreProvider } from './src/state/store';

export default ({ element }: WrapRootElementBrowserArgs | WrapRootElementNodeArgs) => {


  // const isSiteFramed = serverData?.isSiteFramed;

  // const contentfulPreviewEnabled = process.env.GATSBY_CONTENTFUL_PREVIEW_ENABLED === 'true' && !(typeof window === 'undefined');

  return (
    <ShopifyProvider
      storefrontId={process.env.GATSBY_SHOPIFY_SHOP_ID as string}
      storeDomain={`https://${process.env.GATSBY_SHOPIFY_MYSHOPIFY_DOMAIN}`}
      storefrontToken={process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN as string}
      storefrontApiVersion={process.env.GATSBY_SHOPIFY_STOREFRONT_API_VERSION as string}
      countryIsoCode={process.env.GATSBY_SHOPIFY_COUNTRY_ISO_CODE as CountryCode}
      languageIsoCode={process.env.GATSBY_SHOPIFY_LANGUAGE_ISO_CODE as LanguageCode}
    >
      <ShopifyCookieProvider>
        <CartProvider
          cartFragment={cartFragment}
        >
          {/* <HydrateStateProvider> */}
          <ThemeProvider theme={theme}>
            <CssBaseline enableColorScheme />
            <SnackbarProvider maxSnack={1} autoHideDuration={5000} variant='success' classes={{
              containerRoot: 'snackbar-container-root',
            }} disableWindowBlurListener={true} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
              {/* <ContentfulLivePreviewProvider debugMode={false} targetOrigin={'https://app.contentful.com'} enableLiveUpdates={contentfulPreviewEnabled} enableInspectorMode={contentfulPreviewEnabled} locale="en-US"> */}
              {element}
              {/* </ContentfulLivePreviewProvider> */}
            </SnackbarProvider>
          </ThemeProvider>
          {/* </HydrateStateProvider> */}
        </CartProvider>
      </ShopifyCookieProvider>
    </ShopifyProvider>
  )
}

const ShopifyCookieProvider = ({ children }: { children: React.ReactNode }) => {
  // TODO: integrate with consent popup
  const hasUserConsent = true;
  useShopifyCookies({ hasUserConsent, domain: process.env.GATSBY_SHOPIFY_COOKIE_DOMAIN });

  return <>{children}</>
}