import GlobalStyles from "@mui/material/GlobalStyles";
import { WrapPageElementBrowserArgs, WrapPageElementNodeArgs } from "gatsby";
import React from "react";
import Layout from "./src/components/layout";

const globalStyles = <GlobalStyles
  styles={(theme) => ({
    '.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg': {
      height: '60px!important',
      width: '20px!important',
      stroke: 'black'
    },
    '.image-gallery-icon': {
      filter: 'none!important',
    },
    '.image-gallery-thumbnail, .image-gallery-thumbnail.active, .image-gallery-thumbnail:focus, .image-gallery-thumbnail:focus-visible, .image-gallery-thumbnail:hover': {
      border: 'none!important',
      transition: 'none!important'
    },
    '.image-gallery-thumbnail-image:focus-visible': {
      outline: 'none!important',
      border: 'none!important',
      transition: 'none!important'
    },
    '.html p': {
      margin: '0',
      marginBottom: '10px'
    },
    'html': {
      overflowX: 'hidden'
    },
    'body': {
      overflowX: 'hidden'
    },
    '.snackbar-container-root .notistack-MuiContent-success': {
      backgroundColor: theme.palette.success.main
    },
    '.MuiAccordionDetails-root p': {
      whiteSpace: 'pre-wrap'
    },
    '.MuiAccordionSummary-content': {
      fontWeight: 'bold'
    },
    '.gatsby-image-wrapper .above-fold': {
      transition: 'none!important',
      willChange: 'unset!important'
    },
    'a.jdgm-write-rev-link': {
      fontWeight: 'normal!important',
      borderRadius: '14px!important',
      color: 'black!important',
      backgroundColor: 'white!important',
    },
    '.jdgm-all-reviews-widget': {
      width: '100%'
    },
    '.jdgm-branding-footer.jdgm-link': {
      display: 'none'
    },
    '.jdgm-rev-widg__title': {
      display: 'none'
    },
    '.jdgm-widget.jdgm-review-widget': {
      width: '100%',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: 0,
      paddingBottom: theme.spacing(2),
    },
    '.judgeme-reviews-container .jdgm-row-actions': {
      borderTop: '1px solid',
      BorderColor: theme.palette.grey[100] + "!important",
      paddingTop: theme.spacing(2),
      marginTop: theme.spacing(1),
    }
  })}
/>

export default ({ element, props }: WrapPageElementBrowserArgs | WrapPageElementNodeArgs) => {
  const isSiteFramed = false;

  return (
    <>
      {globalStyles}
      <Layout location={props.location} isSiteFramed={isSiteFramed}>
        {element}
      </Layout>
    </>
  )
}
